import React from 'react';
import {
  FaFacebookSquare,
  FaInstagram,
  FaTwitterSquare,
} from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, 
         faMobile, 
        faEnvelope, 
        faIdCard } from '@fortawesome/free-solid-svg-icons';
import logo from './logoFQ.png';

const Footer = () => {
  return (
    <div className='max-w-[1800px] mx-auto px-4 grid lg:grid-cols-2 gap-4 bg-black text-gray-300'>
      <div className="flex items-center lg:col-span-1">
        <img src={logo} alt="Logo" width="125" height="125" className="mr-4"/>
        <div>
          <h1 className='text-2xl font-bold text-[#00df9a]'>Fast Quality Installation Network and Data Solutions</h1>
          <p className='font-mono'>Connecting people and applications through skillfully executed technology infrastructure, design, and deployment.</p>
          <div className='flex justify-start md:w-[100%] my-6'>
            <a href="https://www.facebook.com/profile.php?id=100078165424193" className="mr-2">
              <FaFacebookSquare size={30} />
            </a>
            <a href="https://www.instagram.com" className="mr-2">
              <FaInstagram size={30} />
            </a>
            <a href="https://twitter.com" className="mr-2">
              <FaTwitterSquare size={30} />
            </a>
          </div>
        </div>
      </div>
      <div className='lg:col-span-1 flex justify-evenly mt-4'>
        <div>
            <h6 className='font-bold font-mono text-gray-400'>Our Services</h6>
            <ul>
                <li className='py-2 text-sm'>Structured Cabling</li>
                <li className='py-2 text-sm'>Wifi Installation</li>
                <li className='py-2 text-sm'>CCTV Installation</li>
                <li className='py-2 text-sm'>Fire System Installation</li>
            </ul>
        </div>
        <div>
            <h6 className='font-bold font-mono text-gray-400'>Company Address</h6>
            <ul>
                <li className='py-2 text-sm'><FontAwesomeIcon icon={faMapMarker} /> #250D MLQ ST. Purok 3 New Lower Bicutan Taguig City.</li>
                <li className='py-2 text-sm'><FontAwesomeIcon icon={faMobile} />  09776503097</li>
                <li className='py-2 text-sm'><FontAwesomeIcon icon={faEnvelope} />  fqinadsolution@gmail.com</li>
                <li className='py-2 text-sm'><FontAwesomeIcon icon={faIdCard} />  DTI Business No.: 2783643 </li>
            </ul>
        </div>
      </div>
      <div className="text-center text-sm py-2">
        <p>&copy; {new Date().getFullYear()} Fast Quality Installation Network and Data Solution. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
