import React from 'react';
import BackgroundImage from '../assets/image/BG.jpg'; // Ensure this path is correct
import { ReactTyped } from 'react-typed';


const Hero = () => {
  return (
    <div
      className="mx-auto max-w-[1800px] h-96 pb-0 text-center flex flex-col justify-center"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: 'cover', // Adjust size (cover, contain, or specific sizes)
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '1000px',
      }}
    >
      <div className="max-w-[1000px] w-full mx-auto text-center flex flex-col justify-center bg-transparent pt-20 pb-10">
        <p className="font-bold text-5xl text-[#16dbe9] mb-10" style={{ textShadow: '4px 4px 6px rgba(0, 0, 0, 0.7)' }}>
          FAST QUALITY
        </p>
        <p className="font-bold text-5xl text-[#16dbe9] mb-10" style={{ textShadow: '4px 4px 6px rgba(0, 0, 0, 0.7)' }}>
          Network and Data Solution
        </p>
        <ReactTyped 
          className='font-bold decoration-from-font text-4xl border text-white bg-black bg-opacity-30 mb-8 rounded-lg px-4 py-2 hover:bg-opacity-10 transition duration-300 w-full'
          style={{ textShadow: '4px 4px 6px rgba(0, 0, 0, 0.7)' }}
          strings={['Structured Cabling', 'WIFI Installation', 'CCTV Installation', 'FIRE Alarm System Installation']}
          typeSpeed={120}
          backSpeed={140}
          loop
        />
        <h1 className="font-bold text-2xl border text-white bg-black bg-opacity-30 mb-8 rounded-lg px-4 py-2 hover:bg-opacity-10 transition duration-300 w-full" 
          style={{ textShadow: '4px 4px 6px rgba(0, 0, 0, 0.7)' }}>
          In today’s rapidly evolving world of technology, the demand for seamless connectivity and efficient communication has become paramount.
          This is where structured cabling steps in as the unsung hero, providing the backbone for modern IT systems. From small businesses to
          large enterprises, structured cabling forms the foundation that supports the reliable and fast transmission of data and voice signals.
          By organizing and standardizing the infrastructure, structured cabling ensures that all components within a network are seamlessly integrated,
          allowing for smooth and uninterrupted operations.
        </h1>
        <a href="./#Contacts">
        <button className='bg-[#1636e9] bg-opacity-50 font-mono font-bold w-[200px] text-white text-2xl rounded-md my-6 mx-auto px-6 py-3'>
            Get Quote
        </button>
        </a>
      </div>
    </div>
  );
};

export default Hero;
