import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import logo from './logoFQ.png';
import './Navbar.css';

const Navbar = () => {
  const [navOpen, setNavOpen] = useState(false);
  const navRef = useRef(null);

  const handleNav = () => {
    setNavOpen(!navOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setNavOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="fixed top-0 left-0 right-0 z-50 overflow-x-hidden">
      <div className="flex justify-between items-center h-16 max-w-[1800px] mx-auto px-4 border-b-2 border-gray-500 text-white bg-black bg-opacity-90" ref={navRef}>
        <img src={logo} alt="Logo" width="125" height="200" className="transition duration-300 transform hover:scale-105" />
        
        {/* Desktop Title */}
        <h2 className="hidden md:block font-mono font-bold text-[#16dbe9] px-2 py-2 text-2xl"
          style={{ textShadow: '6px 6px 18px rgba(0, 0, 0, 0.9)' }}>
          Fast Quality Installation Network and Data Solutions
        </h2>
        
        {/* Desktop Menu */}
        <ul className="hidden md:flex space-x-4">
          {['Home', 'About', 'Services', 'Projects', 'Contacts'].map((item) => (
            <li key={item} className="p-4 font-semibold text-[#16dbe9] border-b-2 border-transparent hover:border-white transition duration-300 ease-in-out">
              <a href={`#${item.toLowerCase()}`}>{item}</a>
            </li>
          ))}
        </ul>
        
        {/* Mobile Menu Button */}
        <div onClick={handleNav} className="block md:hidden cursor-pointer" aria-label={navOpen ? "Close menu" : "Open menu"}>
          {navOpen ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={30} />}
        </div>

        {/* Mobile Sidebar Menu */}
        <div className={`${navOpen ? 'translate-x-0' : '-translate-x-full'} fixed left-0 top-0 w-full h-screen bg-gray-900 text-white transition-transform duration-300 ease-in-out md:hidden`}>
          <div className="flex justify-between items-center p-4 border-b border-gray-700">
            <img src={logo} alt="Logo" width="100" height="100" />
            <AiOutlineClose size={30} onClick={handleNav} className="cursor-pointer" />
          </div>
          <h1 className="text-3xl font-bold text-[#00df9a] m-4">Fast Quality</h1>
          <ul className="uppercase p-4 space-y-2">
            {['Home', 'About', 'Services', 'Projects', 'Contacts'].map((item) => (
              <li key={item} className="p-4 font-bold border-b border-gray-500 hover:bg-gray-700 transition duration-300 ease-in-out">
                <a href={`#${item.toLowerCase()}`} onClick={handleNav}>{item}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
