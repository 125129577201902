import React from 'react';
import Laptop from '../assets/image/about BG.jpg';

const AboutUs = () => {
  return (
    <section className="about-section py-16 px-4">
      <div className="container max-w-[1800px] mx-auto grid md:grid-cols-2 gap-4">
        <div className="image-wrapper">
          <img
            className="custom-image w-full max-w-[1800px] mx-auto"
            src={Laptop}
            alt="About Background"
          />
        </div>
        
        <div className="content flex flex-col justify-center">
          <h2 className="heading text-2xl md:text-4xl font-bold text-black text-shadow-lg mb-4">
            About Us
          </h2>
          
          <h3 className="subheading md:text-4xl text-2xl font-bold text-shadow-lg mb-6">
            Fast Quality History
          </h3>
          
          <div className="description space-y-4 text-justify leading-relaxed text-xl text-gray-700">
            <p>
              Fast Quality Installation Network and Data Solution (FQIND) is a company established in 2008, committed to excellence in structured cabling and fiber optic uplink. Founded by <b>Mr. Armando V. Largo</b> with his son <b>Edsel Largo</b>, FQIND strives to provide top-notch services to clients.
            </p>
            
            <p>
              Staffed with experienced professionals who embody strong work ethics and values, FQIND is renowned for delivering high-quality services. The team ensures that each project, simple or complex, is completed on time and to the highest standards.
            </p>
            
            <p>
              Originally founded as Topnet Electrical and Cable Installation, FQIND was officially registered with the Department of Trade and Industry on June 10, 2008. The company is a result of Mr. Largo’s professional experience and vision to build a family-owned business.
            </p>
            
            <p>
              Today, FQIND continues to uphold its commitment to excellence in structured cabling and fiber optic uplink, delivering optimal results for every client.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;