import React from 'react';
import Service1 from '../assets/image/services 1.png';
import Service2 from '../assets/image/services 2.png';
import Service3 from '../assets/image/services 3.png';
import Service4 from '../assets/image/services 4.png';

const Cards = () => {
  return (
    <div className='w-full py-16 px-4 bg-[#13c8ec]'>
      <div className='max-w-[1240px] mx-auto'>
        <h1 className='text-4xl text-center p-4 font-mono font-bold pb-4'>Our Service</h1>
        <p className='text-2xl text-center p-4 font-mono font-bold pb-8'>
        If you're running a business, it's important to make sure that your infrastructure is sound. 
        This means having a reliable network and good wiring. 
        One of the most critical aspects of achieving this is having a structured cabling system in place.</p>
        <div className='grid md:grid-cols-4 gap-8 mt-8'>
          <div className='w-full shadow-xl flex flex-col p-4 rounded-lg hover:scale-105 duration-300 bg-gray-300'>
            <img className='w-20 mx-auto mt-[-3rem] bg-transparent' src={Service1} alt="Service 1" />
            <h2 className='text-2xl text-center py-8'>Structured Cabling</h2>
            <p className='text-center text-lg font-mono'>
            We specialize in testing, recommending, and installing high-performance data cables, both copper and fiber-optic. With experience helping thousands of businesses across various sectors, we future-proof wireless networks for tomorrow's technology.
            </p>
          </div>
          <div className='w-full shadow-xl flex flex-col p-4 rounded-lg hover:scale-105 duration-300 bg-gray-300'>
            <img className='w-20 mx-auto mt-[-3rem] bg-transparent' src={Service2} alt="Service 2" />
            <h2 className='text-2xl text-center py-8'>Wifi Cabling Installation</h2>
            <p className='text-center text-lg font-mono'>
            High-speed fiber optic internet, with its unparalleled speed, can revolutionize small businesses, nonprofits, and professional offices. It enhances online training, interviews, video conferencing, telecommuting, and cloud services, making them practical and efficient.
            </p>
           </div>
          <div className='w-full shadow-xl flex flex-col p-4 rounded-lg hover:scale-105 duration-300 bg-gray-300'>
            <img className='w-20 mx-auto mt-[-3rem] bg-transparent' src={Service3} alt="Service 3" />
            <h2 className='text-2xl text-center py-8'>CCTV Installation</h2>
            <p className='text-center text-lg font-mono'>
            We provide professional CCTV installation and repair services for residential and commercial properties. Our dedicated technicians ensure exceptional security and surveillance for your premises.
            </p>
          </div>
          <div className='w-full shadow-xl flex flex-col p-4 rounded-lg hover:scale-105 duration-300 bg-gray-300'>
            <img className='w-20 mx-auto mt-[-3rem] bg-transparent' src={Service4} alt="Service 4" />
            <h2 className='text-2xl text-center py-8'>Fire Detection and Alarm System</h2>
            <p className='text-center text-lg font-mono'>
            We offer a thoughtful approach to designing fire alarm systems, using equipment from leading manufacturers instead of proprietary systems. This gives you the freedom to choose the best systems and service partners. Our designers and installers ensure your system meets all required codes and standards.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
