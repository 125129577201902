import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import './Contacts.css'; // Ensure this file exists and has the correct styles

const Contacts = () => {
    const form = useRef();
    const [message, setMessage] = useState(''); // Initialize the state

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_14a1d5p', 'template_6anyekz', form.current, 'vyMbGBScTy-9ViC58')
            .then((result) => {
                console.log(result.text);
                setMessage('Your message has been sent successfully!');
            }, (error) => {
                console.log(error.text);
                setMessage('Failed to send the message. Please try again.');
            });

        form.current.reset(); // Reset the form after sending
    };

    return (
        <div className="contact-container">
            <div className="contact-heading">
                <h2>Get in Touch</h2>
                <p>Have questions or need assistance? We're here to help! Reach out to us using the form below, and we'll get back to you promptly.</p>
            </div>
            <div className="contact-column">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.019207784915!2d-122.40139648468116!3d37.793733179755555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80858064d9e22b85%3A0xcca9a5d4edc8b1b3!2sSalesforce%20Tower!5e0!3m2!1sen!2sus!4v1588613519599!5m2!1sen!2sus"
                    width="100%"
                    height="310"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                    title="Location Map"
                ></iframe>
            </div>
            <div className="contact-column">
                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" name="user_name" placeholder="Name" required />
                    <input type="email" name="user_email" placeholder="Email" required />
                    <textarea name="message" placeholder="Message" required />
                    <input type="submit" value="Send" />
                </form>
                {message && <p className="success-message">{message}</p>}
            </div>
        </div>
    );
};

export default Contacts;
