import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';

import images2 from '../assets/image/Image 2.jpg';
import images3 from '../assets/image/Image 3.jpg';
import images4 from '../assets/image/Image 4.jpg';
import images5 from '../assets/image/Image 5.jpg';
import images6 from '../assets/image/Image 6.jpg';
import images7 from '../assets/image/Image 7.JPG';
import images8 from '../assets/image/Image 8.JPG';
import images9 from '../assets/image/Image 9.JPG';
import images10 from '../assets/image/Image 10.JPG';

const Projects = () => {
  const [projects, setProjects] = useState([]);

  const hardcodedProjects = [
    {
      id: 1,
      title: 'IFF WYNSUM',
      description: 'Smart Door Access and Biometrix',
      year: '2023',
      imageUrl: images2,
    },
    {
      id: 2,
      title: 'Project Accomplished',
      description: 'Each cable is labeled and documented to ensure easy identification and traceability. This includes labeling both ends of the cables with unique identifiers and maintaining detailed records of cable routes and connections for future reference.',
      year: '2022',
      imageUrl: images3,
    },
    {
      id: 3,
      title: 'BE SAFE MED',
      description: 'Structured cabling for Data, WIFI, CCTV and Data Cabinet',
      year: '2023',
      imageUrl: images4,
    },
    {
      id: 4,
      title: 'OLFU',
      description: 'Structured Cabling (Copper and Fiber)',
      year: '2023',
      imageUrl: images5,
    },
    {
      id: 5,
      title: 'Project Accomplished',
      description: 'Cables are carefully routed along designated pathways, avoiding sharp bends and obstructions. Velcro straps are used to bundle cables together, allowing for easy adjustments and additions without the need for cutting and replacing ties.',
      year: '2018-2022',
      imageUrl: images6,
    },
    {
      id: 6,
      title: 'University of Santo Tomas',
      description: 'Check compatibility and connect the projector to the network via Ethernet and Wi-Fi.',
      year: '2023',
      imageUrl: images7,
    },
    {
      id: 7,
      title: 'ATALIAN MEGATOWER',
      description: 'Only high-quality cables, connectors, and accessories are used in the cable rack system, ensuring reliability and longevity. This commitment to quality minimizes the risk of cable failures and performance issues, providing peace of mind to data center operators.',
      year: '2023',
      imageUrl: images8,
    },
    {
      id: 8,
      title: 'De la Salle University',
      description: 'Plan and route cables neatly within the rack using cable management accessories like cable trays, raceways, and Velcro straps. Separate power and data cables to minimize interference.',
      year: '2023',
      imageUrl: images9,
    },
    {
      id: 9,
      title: 'Concentrix Ayala',
      description: 'We provide detailed documentation of the installation process, including speaker placements, network configurations, and equipment specifications.',
      year: '2023',
      imageUrl: images10,
    },
  ];

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await axios.get('http://localhost:3002/projects');
      setProjects(response.data); // Assuming the projects array is directly returned
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const combinedProjects = [...hardcodedProjects, ...projects];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className='w-full py-8 px-2'>
        <div className='max-w-[1240px] mx-auto'>
          <h1 className='text-5xl text-center font-mono font-bold text-black'>Our Projects</h1>
          <p className='text-xl text-center font-mono font-light text-black mt-4'>
            At Fast Quality, we go beyond your expectations by delivering results that truly impress. Our innovative approach drives us to push boundaries and achieve excellence in every project. Let’s turn your vision into reality together.
          </p>
        </div>
      </div>
      <div className='w-full py-8'>
        <div className='max-w-[1240px] mx-auto'>
          <Slider {...settings}>
            {combinedProjects.map((project) => (
              <div key={project.id} className='p-4'>
                <div className='bg-white bg-opacity-50 shadow-lg rounded-lg overflow-hidden' style={{ height: '600px' }}>
                  <div className='h-2/3 overflow-hidden'>
                    <img src={project.imageUrl} alt={project.title} className='w-full h-full object-cover' />
                  </div>
                  <div className='p-4'>
                    <h3 className='text-2xl font-semibold mb-2'>{project.title}</h3>
                    <p className='text-gray-700 text-sm'>{project.description}</p>
                    <div className='mt-4'>
                      <span className='inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2'></span>
                      <span className='inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700'>{project.year}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Projects;
